
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Container, Form, Row, Image, Card, Alert} from 'react-bootstrap';
import { useState } from 'react';
import { useQRCode } from 'next-qrcode';
import { SliderPicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faRedo } from '@fortawesome/free-solid-svg-icons'
import logo from './Assets/Images/colored_transparent_logo.webp';
import ContrastChecker from './ContrastChecker';


function App() {
  const [qr, setQR] = useState('https://cryosoft.co.ke');
  const [foreGround, setForeGround] = useState("#010599FF");
  const [backGround, setBackGround] = useState('#FFBF60FF');
  const [showAlert, setShowAlert] = useState(false);
  interface qrProps {
    text: string,
    foreGround: string,
    backGround: string
  }
  interface alertProps {
    showAlert:boolean,
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  }
  const CreateQr = (props:qrProps) => {
    let gottenText = props.text===""?"https://cryosoft.co.ke":props.text;
    const { inputRef } = useQRCode<HTMLCanvasElement>({
      text: gottenText,
      options: {
        type: 'image/png', 
        level: 'H',
        margin: 7,
        scale: 10,
        color: {
          dark: props.foreGround,
          light: props.backGround,
        },
      },
    });
    return <img ref={inputRef} id="canvas" className="img-fluid" alt="QR generated"/>;
  }
  function AlertDismissible(props:alertProps) {
    setTimeout(()=>{props.setShowAlert(false)}, 10000);
      return (
        <Alert variant="success" onClose={() => props.setShowAlert(false)} dismissible 
        className={(props.showAlert?"":"d-none")}>
            QR has been downloaded to your device
        </Alert>
      );

  }
  function download(){
    var canvas = document.getElementById("canvas") as HTMLImageElement;
    var url = canvas.src;
    var link = document.createElement('a');
    link.download = 'download.png';
    link.href = url;
    link.click();
  }
  const reset =()=>{
    setQR('https://cryosoft.co.ke');
    setForeGround("#010599FF");
    setBackGround('#FFBF60FF');
  }
  return (
    <Container style={{marginTop:80}}>
      <div className="mx-auto text-center" style={{marginBottom:40}}>
      <Image src={logo} rounded height={80} alt="Cryosoft Logo"/>
        <h3>QR Code Generator 2</h3>
      </div>
      
      <Row >
        <Col lg={{span:7}} xs={{span:12, order:1}}>
          <Row>
          <Col lg={12} sm={12}>
            <AlertDismissible showAlert={showAlert} setShowAlert={setShowAlert}/>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><b>Enter Text or Link to Generate QR</b></Form.Label>
              <Form.Control type="text" placeholder="Type anything" onChange={(e)=>setQR(e.target.value)} value={qr}/>
              <Form.Text className="text-muted">
                We'll never share whatever you type here.
              </Form.Text>
            </Form.Group>
            </Col>
            <Row>
              <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><b>ForeGround</b></Form.Label>
              <SliderPicker onChange={(color)=>setForeGround(color.hex)} color={foreGround}/>
             
            </Form.Group>
            </Col>
            <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><b>Background</b></Form.Label>
              <SliderPicker onChange={(color)=>setBackGround(color.hex)} color={backGround}/>
             
            </Form.Group>
            </Col>
            </Row>
            </Row>
        </Col>
        <Col lg={{span:3, order:12}} className="text-center mx-auto" style={{marginBottom:20}}>
          <Card>
            <Card.Body>
          <CreateQr text={qr} foreGround={foreGround} backGround={backGround}/>
          </Card.Body>
          <Card.Footer style={{backgroundColor:"white"}}>
            <Button variant="primary" onClick={()=>{download();setShowAlert(true)}}><FontAwesomeIcon icon={faDownload} /> Download</Button>
            <Button style={{marginLeft:10}} variant="danger" onClick={()=>reset()}><FontAwesomeIcon icon={faRedo} /> Reset</Button>
          </Card.Footer>
          </Card>
        </Col>
      </Row>
      <footer className="blockquote-footer text-center my-5">
        Made with ❤️ by <a href="https://cryosoft.co.ke" target="_blank">Cryosoft Corporation</a>
      </footer>
    </Container>
  );
}

export default App;
